import React, {useContext, useState} from 'react';
import { IconButton, Dialog, DialogContent, Box, Typography, TextField, Button, CircularProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import globalContext from '../../../../context/global-context';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';

const ProductionChargepointOCPPUpdate = props => {

    const {mode, cp} = props;
    const context = useContext(globalContext);
    const [open, setOpen] = useState(false);
    const [identity, setIdentity] = useState(cp.identity);
    const [sysAlias, setSysAlias] = useState(cp.sys_alias || "");
    const [transfer, setTransfer] = useState(cp.transfer_in_identity || "");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleClose = () => {
        setOpen(false);
    }

    const handleSubmit = async () => {

        try {

            if(!cp.id_chargepoint)
                throw("No chargepoint id provided");

            if(identity === null || identity === "")
                throw("An identity must be set");
    
            setIsLoading(true);
            setError(null);
    
            try {
            
                const res = await context.apiRequest(`/service_consumer/ocpp/chargepoint`, 'PATCH', {identity, sys_alias: sysAlias, transfer_in_identity: transfer, server: mode, id_chargepoint: cp.id_chargepoint});
                if(res.success){
                    if(typeof props.onSuccess === "function")
                        props.onSuccess(identity);

                    handleClose();
                }
                
            } catch (err) {
    
                throw(typeof err === 'string' ? err : typeof err?.msg === 'string' ? err.msg : JSON.stringify(err));
                
            } finally {
                setIsLoading(false);
            }
            
        } catch (error) {
            setError(error);
        }

    }

    return (
        <React.Fragment>
            <IconButton color={mode === "TEST" ? "primary" : "secondary"} size="small" onClick={() => setOpen(true)} style={{marginLeft: 20}}><EditIcon /></IconButton>
            <Dialog open={open} fullWidth maxWidth="xs" onClose={handleClose}>
                <DialogContent>
                    <Box style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Typography variant="h6" style={{display: 'flex', alignItems: 'center', gap: 15}}>Update OCPP Chargepoint</Typography>
                        <IconButton onClick={handleClose}><CloseIcon /></IconButton>
                    </Box>
                    <Box style={{display: 'flex', flexDirection: 'column', gap: 20, marginTop: 20, marginBottom: 20}}>
                        {error && <Alert variant="filled" severity="error">{error}</Alert>}
                        
                        <TextField
                            style={{ width: 400 }}
                            id="identity"
                            label="Identity"
                            helperText="ROLEC_DJK12345"
                            variant="filled"
                            value={identity}
                            onChange={e => setIdentity(e.target.value)}
                        />

                        <TextField
                            style={{ width: 400 }}
                            id="sysAlias"
                            label="Sys Alias"
                            helperText="UKEV10123"
                            variant="filled"
                            value={sysAlias}
                            onChange={e => setSysAlias(e.target.value)}
                        />

                        <TextField
                            style={{ width: 400 }}
                            id="transfer"
                            label="Transfer In Identity"
                            helperText=""
                            variant="filled"
                            value={transfer}
                            onChange={e => setTransfer(e.target.value)}
                        />

                        <Button onClick={handleSubmit} variant="contained" color={mode === "TEST" ? "primary" : "secondary"} disabled={isLoading}>{isLoading ? <CircularProgress color={mode === "TEST" ? "primary" : "secondary"} size={20} /> : 'Update'}</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default ProductionChargepointOCPPUpdate;