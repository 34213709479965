import React from 'react';
import { Dialog, DialogContent, Box, Typography, IconButton, List, ListItem } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import ProductionChargepointOCPPConfigurationDelete from './chargepoint_ocpp_configuration_delete'
import ProductionChargepointOCPPUpdate from './chargepoint_ocpp_update';


const ProductionChargepointOCPPConfiguration = props => {


    const {mode} = props;
    const handleClose = () => {
        if(typeof props.onClose === 'function')
            props.onClose();
    }

    const handleDeleteOverrideSuccess = args => {
        if(typeof props.onOverrideDelete === "function")
            props.onOverrideDelete(args);
    }

    const handleUpdateSuccess = (identity) => {
        if(typeof props.onUpdate === "function")
            props.onUpdate(identity);
    }

    return (
        <React.Fragment>
            <Dialog open={true} fullWidth maxWidth="sm" onClose={handleClose}>
                <DialogContent>
                    <Box style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Typography variant="h5" style={{display: 'flex', alignItems: 'center', gap: 15}}><InfoIcon/> OCPP Chargepoint</Typography>
                        <IconButton onClick={handleClose}><CloseIcon /></IconButton>
                    </Box>
                    <Typography variant="body2" color="textSecondary" style={{marginBottom: "15px"}}>Chargepoint ID: {props.cp.id_chargepoint}</Typography>

                    <Box style={{display: 'flex', justifyContent: 'space-between'}}>
                        <Box>
                            <Typography variant="body2">Identity: {props.cp.identity}</Typography>
                            <Typography variant="body2">Sys alias: {props.cp.sys_alias}</Typography>
                            <Typography variant="body2">Transfer in identity: {props.cp.transfer_in_identity}</Typography>
                            <Typography variant="body2">Protocol: {props.cp.protocol}</Typography>
                        </Box>

                        <Box>
                            <ProductionChargepointOCPPUpdate cp={props.cp} mode={mode} onSuccess={handleUpdateSuccess} />
                        </Box>
                    </Box>

                    <Box style={{marginTop: 30, paddingBottom: 20}}>
                        <Typography variant="h6">Overrides</Typography>
                        <List dense style={{width: '100%'}}>
                            {props.cp.overrides.map((o, idx) => (
                                <ListItem key={idx}>
                                    <Box style={{display: 'flex', width: "100%", gap: "10px", justifyContent: "space-between", alignItems: 'center'}}>
                                        <Typography variant="body2" style={{wordWrap: "break-word"}}>{o.configuration_key}: <b style={{marginLeft: "10px"}}>{o.configuration_value}</b></Typography>
                                        <ProductionChargepointOCPPConfigurationDelete id_chargepoint={o.id_chargepoint} configuration_key={o.configuration_key} onSuccess={handleDeleteOverrideSuccess} mode={mode}/>
                                    </Box>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}
export default ProductionChargepointOCPPConfiguration;