import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import GlobalContext from './context/global-context';
import { connect } from 'react-redux';

import SignIn from './content/structure/signin';
import SplashScreen from './content/structure/splashscreen';
import DrawerMenu from './content/structure/drawermenu';
import TopToolbar from './content/structure/toolbar';
import NotFound from './content/structure/not_found';

import Dashboard from './content/dashboard/dashboard';
import Transactions from './content/transactions';
import StatementsIndex from './content/statements/';
import Statements from './content/statements/statements';
import Orders from './content/orders/orders';
import Order from './content/orders/order';
import Map from './content/map/map';
import Visits from './content/visits';
import Collapse from '@material-ui/core/Collapse';
import Alert from '@material-ui/lab/Alert';
import Organisations from './content/organisations';
import Organisation from './content/organisations/organisation';
import ChangePassword from './content/user/change_password';
import TicketsDashboard from './content/tickets';
import Tickets from './content/tickets/tickets';
import Reports from './content/reports';
import Commissioning from './content/commission_new';
import Billing from './content/billing';
import Settings from './content/settings';
import Networks from './content/settings/system/networks';
import TicketTypes from './content/settings/tickets/ticket_types';
import TicketStatuses from './content/settings/tickets/ticket_statuses';
import TicketLevels from './content/settings/tickets/ticket_levels';
import TicketPriorities from './content/settings/tickets/ticket_priorities';
import TicketContactTypes from './content/settings/tickets/ticket_contact_types';
import TicketUnits from './content/settings/tickets/ticket_units';
import TicketUnitGroups from './content/settings/tickets/ticket_unit_groups';
import TicketUnitUsers from './content/settings/tickets/ticket_unit_users';
import TicketUnitGroupUsers from './content/settings/tickets/ticket_unit_group_users';
import SystemSectors from './content/settings/system/system_sectors';
import Users from './content/settings/system/users';
import User from './content/settings/system/users/user';
import ControllerTypes from './content/settings/controllers/controller_types';
import ControllerSettings from './content/settings/controllers/controller_settings';
import Network from './content/network';
import ServiceUsers from './content/service_users';
import WalletPayments from './content/service_users/wallet_payments';
import WalletBalances from './content/service_users/wallets';


import { ThemeProvider } from '@material-ui/styles';

import { makeStyles } from '@material-ui/core/styles';
import theme from './theme';
import NotAuthorised from './content/structure/not_authorised';
import ProductSettings from './content/settings/system/products';
import SimProfiles from './content/settings/sims/sim_profiles';
import GprsNetworks from './content/settings/system/gprs_networks';
import TariffProfiles from './content/settings/sims/tariff_profiles';
import ChargeSessions from './content/service_users/charge_sessions';
import VendAppUsers from './content/service_users/app_users';
import Reporting from './content/reporting';
import WalletTransactions from './content/reporting/wallet_transactions';
import PitWalletBalances from './content/reporting/wallet_balances';
import Subscriptions from './content/subscription';
import SubscriptionRenewals from './content/subscription/renewals';
import FirmwareUpdates from './content/settings/firmware/index';
import FirmwareInfo from './content/settings/firmware/view';
import FirmwareAdd from './content/settings/firmware/add';
import OcpiTariffSettings from './content/settings/ocpi/tariffs';
import OcpiStatementDashboard from './content/statements/ocpi';
import OcpiBatch from './content/statements/ocpi/batch';
import CurrencySettings from './content/settings/system/currencies';
import BerthVendStatements from './content/statements/berthvend/statements';
import EVConnectAppUsers from './content/service_users/ev_connect/app_users';
import EVConnectChargepoints from './content/service_users/ev_connect/chargepoints';
import ProductionChargepoints from './content/service_users/ev_connect/production';
import OCPPManagement from './content/service_users/ev_connect/ocpp_management';
import BerthVendWalletBalances from './content/reporting/berthvend/wallet_balances';
import BerthVendWalletTransactions from './content/reporting/berthvend/wallet_transactions';

const useStyles = makeStyles({
  root: {
    minHeight: '100%',
    height: '100%',
    width: '100%',
    margin: 0,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.default
  }
})

const App = props => {

  const context = useContext(GlobalContext);
  const styles = useStyles();

  const [drawer, setDrawer] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);

  const toggleDrawer = (val) => {
    if(typeof val != 'undefined' && typeof val == 'object')
      val = null;

    val = val || (drawer?false:true);
    setDrawer(val);
  }

  const closeDrawer = () => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawer(false);
  };



  const isAuthorised = (sector, component) => {
    if(props.reduxUser.profile.role != 1 && props.reduxUser.profile.system_sectors.findIndex(s => s.system_sector_handle == sector) == -1)
      return NotAuthorised;

    return component;
  }


  const shouldShowChangePassword = () => {
    if(showChangePassword === true || Boolean(props.reduxUser.profile.reset_password) === true)
      return true;
    
    return false;
  }

  return (
      <Router>
        <ThemeProvider theme={theme}>

          <div className={styles.root}>
            
            {context.init && <SplashScreen />}

            {(!context.init && props.reduxUser.status === 'out') && <SignIn></SignIn>}
            
            {(!context.init && props.reduxUser.status === 'in') && 
              <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                
                <DrawerMenu onToggleDrawer={toggleDrawer} onCloseDrawer={closeDrawer} isOpen={drawer} />
                <TopToolbar onToggleDrawer={toggleDrawer} showChangePassword={() => setShowChangePassword(true)}/>
                
                {context.alerts.map(a => (
                  <Collapse key={a.timestamp} in={a.show}>
                    <Alert severity={a.type} onClose={() => {context.closeAlert(a.timestamp)}}>{a.msg}</Alert>
                  </Collapse>
                ))}


                {shouldShowChangePassword() && <ChangePassword open={true} handleClose={() => setShowChangePassword(false)} required={Boolean(props.reduxUser.profile.reset_password)} />}

                <Switch>
                    <Route path={"/"} exact component={Dashboard} ></Route>

                    <Route path={"/transactions"} exact component={isAuthorised('transactions', Transactions)} ></Route>
                    <Route path={"/statements"} exact component={isAuthorised('statements', StatementsIndex)} ></Route>
                    <Route path={"/statements/ve/hosts"} exact component={isAuthorised('statements', Statements)} ></Route>
                    <Route path={"/statements/ve/ocpi"} exact component={isAuthorised('statements', OcpiStatementDashboard)} ></Route>
                    <Route path={"/statements/ve/ocpi/batch/:id"} exact component={isAuthorised('statements', OcpiBatch)} ></Route>
                    <Route path={"/statements/bv/hosts"} exact component={isAuthorised('statements', BerthVendStatements)} ></Route>
                    <Route path={"/map"} exact component={isAuthorised('map', Map)} ></Route>
                    <Route path={"/visits"} exact component={isAuthorised('visits', Visits)} ></Route>


                    <Route path={"/orders"} exact component={isAuthorised('orders', Orders)} ></Route>
                    <Route path={"/orders/:preset"} exact component={isAuthorised('orders', Orders)} ></Route>
                    <Route path={"/order/:id"} exact component={isAuthorised('orders', Order)} ></Route>

                    <Route path={"/subscriptions"} exact component={isAuthorised('orders', Subscriptions)} ></Route>
                    <Route path={"/subscriptions/renewals"} exact component={isAuthorised('orders', SubscriptionRenewals)} ></Route>

                    <Route path={"/organisations"} exact component={isAuthorised('organisations', Organisations)} ></Route>
                    <Route path={"/organisation/:id"} exact component={isAuthorised('organisations', Organisation)} ></Route>

                    <Route path={"/tickets"} exact component={isAuthorised('tickets', TicketsDashboard)} ></Route>
                    <Route path={"/tickets/monitor"} exact component={isAuthorised('tickets', Tickets)} ></Route>
                    <Route path={"/tickets/monitor/:id"} exact component={isAuthorised('tickets', Tickets)} ></Route>

                    <Route path={"/reports"} exact component={isAuthorised('reports', Reports)} ></Route>
                    <Route path={"/commissioning"} exact component={isAuthorised('commission', Commissioning)} ></Route>
                    <Route path={"/billing"} exact component={isAuthorised('billing', Billing)} ></Route>
                    
                    <Route path={"/service_users"} exact component={isAuthorised('network', ServiceUsers)} ></Route>
                    <Route path={"/service_users/wallet/payments"} exact component={isAuthorised('network', WalletPayments)} ></Route>
                    <Route path={"/service_users/wallets"} exact component={isAuthorised('network', WalletBalances)} ></Route>
                    <Route path={"/service_users/charge_sessions"} exact component={isAuthorised('network', ChargeSessions)} ></Route>
                    <Route path={"/service_users/app_users"} exact component={isAuthorised('network', VendAppUsers)} ></Route>
                    <Route path={"/service_users/ev_connect/app_users"} exact component={isAuthorised('network', EVConnectAppUsers)} ></Route>
                    <Route path={"/service_users/ev_connect/chargepoints"} exact component={isAuthorised('network', EVConnectChargepoints)} ></Route>
                    <Route path={"/service_users/production/chargepoints"} exact component={isAuthorised('network', ProductionChargepoints)} ></Route>
                    <Route path={"/service_users/production/ocpp"} exact component={isAuthorised('network', OCPPManagement)} ></Route>

                    <Route path={"/reporting"} exact component={isAuthorised('reporting', Reporting)} ></Route>
                    <Route path={"/reporting/wallet_transactions"} exact component={isAuthorised('reporting', WalletTransactions)} ></Route>
                    <Route path={"/reporting/wallet_balances"} exact component={isAuthorised('reporting', PitWalletBalances)} ></Route>
                    <Route path={"/reporting/bv/wallet_transactions"} exact component={isAuthorised('reporting', BerthVendWalletTransactions)} ></Route>
                    <Route path={"/reporting/bv/wallet_balances"} exact component={isAuthorised('reporting', BerthVendWalletBalances)} ></Route>

                    <Route path={"/network"} exact component={isAuthorised('network', Network)} ></Route>

                    <Route path={"/settings"} exact component={isAuthorised('settings', Settings)} ></Route>
                    <Route path={"/settings/users"} exact component={isAuthorised('settings', Users)} ></Route>
                    <Route path={"/settings/users/:id"} exact component={isAuthorised('settings', User)} ></Route>
                    <Route path={"/settings/networks"} exact component={isAuthorised('settings', Networks)} ></Route>
                    <Route path={"/settings/gprs_networks"} exact component={isAuthorised('settings', GprsNetworks)} ></Route>
                    <Route path={"/settings/ticket_types"} exact component={isAuthorised('settings', TicketTypes)}  ></Route>
                    <Route path={"/settings/ticket_statuses"} exact component={isAuthorised('settings', TicketStatuses)} ></Route>
                    <Route path={"/settings/ticket_levels"} exact component={isAuthorised('settings', TicketLevels)} ></Route>
                    <Route path={"/settings/ticket_priorities"} exact component={isAuthorised('settings', TicketPriorities)} ></Route>
                    <Route path={"/settings/ticket_contact_types"} exact component={isAuthorised('settings', TicketContactTypes)} ></Route>
                    <Route path={"/settings/ticket_units"} exact component={isAuthorised('settings', TicketUnits)} ></Route>
                    <Route path={"/settings/ticket_unit_groups"} exact component={isAuthorised('settings', TicketUnitGroups)} ></Route>
                    <Route path={"/settings/ticket_unit_users"} exact component={isAuthorised('settings', TicketUnitUsers)} ></Route>
                    <Route path={"/settings/ticket_unit_group_users"} exact component={isAuthorised('settings', TicketUnitGroupUsers)} ></Route>
                    <Route path={"/settings/system_sectors"} exact component={isAuthorised('settings', SystemSectors)} ></Route>
                    <Route path={"/settings/controller_types"} exact component={isAuthorised('settings', ControllerTypes)} ></Route>
                    <Route path={"/settings/controller_settings"} exact component={isAuthorised('settings', ControllerSettings)} ></Route>
                    <Route path={"/settings/products"} exact component={isAuthorised('settings', ProductSettings)} ></Route>
                    <Route path={"/settings/currencies"} exact component={isAuthorised('settings', CurrencySettings)} ></Route>
                    <Route path={"/settings/sim_profiles"} exact component={isAuthorised('settings', SimProfiles)} ></Route>
                    <Route path={"/settings/tariff_profiles"} exact component={isAuthorised('settings', TariffProfiles)} ></Route>
                    <Route path={"/settings/firmware"} exact component={isAuthorised('firmware', FirmwareUpdates)} ></Route>
                    <Route path={"/settings/firmware/add"} exact component={isAuthorised('firmware', FirmwareAdd)} ></Route>
                    <Route path={"/settings/firmware/:id"} exact component={isAuthorised('firmware', FirmwareInfo)} ></Route>
                    <Route path={"/settings/ocpi_tariffs"} exact component={isAuthorised('settings', OcpiTariffSettings)} ></Route>

                    <Route component={NotFound} />
                </Switch>

              </div>
            }
          </div>
          </ThemeProvider>
      </Router>
    )
}

const mapStateToProps = state => {
  return {
    reduxUser: state.user
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
