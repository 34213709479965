import React, { useState, useContext } from 'react';
import { IconButton, CircularProgress } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import globalContext from '../../../../context/global-context';

const ProductionChargepointOCPPConfigurationDelete = props => {

    const {mode} = props;
    const context = useContext(globalContext);

    const {id_chargepoint, configuration_key} = props;
    const [isLoading, setIsLoading] = useState(false);

    const handleDeleteOverride = async () => {
        setIsLoading(true);

        // Do the thing
        try {
            
            const res = await context.apiRequest(`/service_consumer/ocpp/chargepoint/override`, 'DELETE', {id_chargepoint, configuration_key, server: mode});
            if(res.success)
                if(typeof props.onSuccess === "function")
                    props.onSuccess({id_chargepoint, configuration_key});
            
        } catch (err) {
            
            console.log(err);
            
        } finally {
            setIsLoading(false);
        }

    }

    return (
        <IconButton onClick={handleDeleteOverride} size="small" style={{alignSelf: 'flex-end'}}>
            {isLoading ? <CircularProgress size={22} /> : <DeleteIcon style={{fontSize: 22}} color="error" />}
        </IconButton>
    )
}

export default ProductionChargepointOCPPConfigurationDelete;