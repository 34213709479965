import React, {useContext, useState} from 'react';
import { useTheme, makeStyles, IconButton, Fab, Dialog, DialogContent, Box, Typography, TextField, Button, CircularProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Helpers from '../../global/helpers';

import Quickcrumbs from '../../global/Quickcrumbs';
import SearchBar from '../../../components/searchBar';
import AutoTable from '../../../components/autoTable';
import globalContext from '../../../context/global-context';
import ProductionChargepointOCPPConfiguration from './production/chargepoint_ocpp_configuration';
import ProductionChargepointOCPPAdd from './production/chargepoint_ocpp_add'



const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        overflow: 'auto'
    },
    inner: {
        flex: 1,
        padding: 20,
        flexDirection: 'row'
    },
    taskbar: {
        paddingTop: 20,
        paddingBottom: 20,
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
}));

const OCPPManagement = props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const context = useContext(globalContext);

    const headCells = ["id_chargepoint", "identity", "sys_alias", "transfer_in_identity", "protocol"]
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [chargepoint, setChargepoint] = useState(null);
    const [mode, setMode] = useState("LIVE");

    const loadData = async search => {

        setLoading(true);
        setError(null);
        setData([]);

        if(!search){
            setLoading(false);
            return;
        }

        try {
            
            const res = await context.apiRequest(`/service_consumer/ocpp/chargepoint?server=${mode}&search=${typeof search !== 'undefined' ? `${search}` : ``}`, 'GET');
            if(res.success){
                setData(res.result);

                if(res.result.length > 100)
                    setError('You search returned over 100 results, try refining your search criteria');
            }
            
        } catch (err) {

            setError(typeof err === 'string' ? err : typeof err?.msg === 'string' ? err.msg : JSON.stringify(err));
            setData([]);
            
        } finally {
            setLoading(false);
        }
        
    }

    const handleOnDeleteOverride = args => {
        if(args.id_chargepoint && args.configuration_key){

            const newData = JSON.parse(JSON.stringify(data))
            const idx = newData.findIndex(cp => cp.id_chargepoint === args.id_chargepoint)
            const oIdx = newData[idx].overrides.findIndex(o => o.id_chargepoint === args.id_chargepoint && o.configuration_key === args.configuration_key);
            newData[idx].overrides.splice(oIdx, 1);
            setData(newData);
            setChargepoint(newData[idx]);

        }
    }

    const handleOnUpdate = (identity) => {
        setChargepoint(null);
        loadData(identity);
    }

    const toggleMode = () => {
        setMode(mode !== "LIVE" ? "LIVE" : "TEST");
    }


    return (
        <React.Fragment>
            <div className={classes.container}>
                <div className={classes.inner}>

                    <Quickcrumbs 
                        crumbs={[
                            {
                                name: 'Service Users',
                                route: '/service_users'
                            },
                            {
                                name: 'OCPP Management',
                                route: '/service_users/production/ocpp'
                            }
                        ]}
                        history={props.history}
                    />

                    <div className={classes.taskbar}>
                        <SearchBar 
                            label="Search by identity or sys alias" 
                            onSearch={loadData} 
                            onClear={loadData}
                            shrink={false}
                         />
                         <Button variant="contained" color={mode === "TEST" ? "primary" : "secondary"} onClick={toggleMode} style={{marginLeft: 10}}>{mode}</Button>
                         <ProductionChargepointOCPPAdd onSuccess={identity => loadData(identity)} mode={mode}/>
                    </div>

                    {error && <Alert severity="error">{error}</Alert>}

                    <AutoTable 
                        rows={data} 
                        idCol="id_unit"
                        headCells={headCells}
                        loading={loading}
                        handleRowClick={setChargepoint}
                        cellReplacementProps={{
                            'date_configured': (val, id) => Helpers.toLocaleDateTime(val)
                        }}
                    />

                </div>
            </div>

            {chargepoint && <ProductionChargepointOCPPConfiguration cp={chargepoint} onClose={() => setChargepoint(null)} onOverrideDelete={handleOnDeleteOverride} mode={mode} onUpdate={handleOnUpdate} />}
        </React.Fragment>
    )
}
export default OCPPManagement;