import React from 'react';
import { connect } from 'react-redux';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import {withRouter} from 'react-router-dom';
import { useTheme } from '@material-ui/core';

const DrawerMenu = props => {

    const { user } = props;

    const onChangeRoute = route => {
        props.history.push(route);
    }

    const theme = useTheme();

    const hasSectorAccess = sector => {
        return user != null && (user.role === 1 || user.system_sectors.findIndex(s => s.system_sector_handle === sector) > -1);
    }

    return (
        <Drawer open={props.isOpen} onClose={props.onCloseDrawer()}>
            <div
            style={{width: 250}}
            role="presentation"
            onClick={props.onCloseDrawer()}
            onKeyDown={props.onCloseDrawer()}
            >
            <List color="dark">
                <ListItem>
                    <ListItemText primary={'Menu'} />
                </ListItem>
            </List>
            <Divider />
            <List>
                <ListItem button onClick={() => {onChangeRoute('/')}}>
                   <ListItemText primary={'Dashboard'} />
                </ListItem>
                {hasSectorAccess('organisations') && 
                <ListItem button onClick={() => {onChangeRoute('/organisations')}}>
                    <ListItemText primary={'Organisations'} />
                </ListItem>}
                
                {hasSectorAccess('orders') && 
                <ListItem button onClick={() => {onChangeRoute('/orders')}}>
                    <ListItemText primary={'Orders'} />
                </ListItem>}
                
                {hasSectorAccess('orders') && 
                <div style={{backgroundColor: theme.palette.background.paper}}>
                    <ListItem button onClick={() => {onChangeRoute('/orders/notshipped')}}>
                        <ListItemText primary={'Not Shipped'} style={{paddingLeft: 20}} />
                    </ListItem>
                    <ListItem button onClick={() => {onChangeRoute('/subscriptions/renewals')}}>
                        <ListItemText primary={'Renewals'} style={{paddingLeft: 20}} />
                    </ListItem>
                    {/* 
                        <ListItem button onClick={() => {onChangeRoute('/orders/awaitingproforma')}}>
                            <ListItemText primary={'Awaiting Proforma / Invoice'} style={{paddingLeft: 20}} />
                        </ListItem>
                        <ListItem button onClick={() => {onChangeRoute('/orders/proformaunpaid')}}>
                            <ListItemText primary={'Unpaid Proforma'} style={{paddingLeft: 20}} />
                        </ListItem>
                        <ListItem button onClick={() => {onChangeRoute('/orders/invoiceunpaid')}}>
                            <ListItemText primary={'Unpaid Invoice'} style={{paddingLeft: 20}} />
                        </ListItem>
                    */}
                    <ListItem button onClick={() => {onChangeRoute('/orders/other')}}>
                        <ListItemText primary={'Other'} style={{paddingLeft: 20}} />
                    </ListItem>
                    <Divider />
                </div>}
                
                {hasSectorAccess('network') && 
                <ListItem button onClick={() => {onChangeRoute('/network')}}>
                    <ListItemText primary={'Network'} />
                </ListItem>}
                
                {hasSectorAccess('network') && 
                <ListItem button onClick={() => {onChangeRoute('/service_users')}}>
                    <ListItemText primary={'Service Users'} />
                </ListItem>}
                
                {hasSectorAccess('ocpp_config') && 
                <ListItem button onClick={() => {onChangeRoute('/service_users/production/ocpp')}}>
                    <ListItemText primary={'OCPP Config'} />
                </ListItem>}
                
                {hasSectorAccess('billing') && 
                <ListItem button onClick={() => {onChangeRoute('/billing')}}>
                    <ListItemText primary={'Billing'} />
                </ListItem>}
                
                {hasSectorAccess('tickets') && 
                <ListItem button onClick={() => {onChangeRoute('/tickets')}}>
                    <ListItemText primary={'Tickets'} />
                </ListItem>}
                
                {hasSectorAccess('transactions') && 
                <ListItem button onClick={() => {onChangeRoute('/transactions')}}>
                    <ListItemText primary={'Transactions'} />
                </ListItem>}
                
                {hasSectorAccess('statements') && 
                <ListItem id= "statements"button onClick={() => {onChangeRoute('/statements')}}>
                    <ListItemText primary={'Statements'} />
                </ListItem>}

                {hasSectorAccess('statements') && 
                <div style={{backgroundColor: theme.palette.background.paper}}>
                    <ListItem button onClick={() => {onChangeRoute('/statements/ve/hosts')}}>
                        <ListItemText primary={'VendElectric'} style={{paddingLeft: 20}} />
                    </ListItem>
                    <ListItem button onClick={() => {onChangeRoute('/statements/bv/hosts')}}>
                        <ListItemText primary={'BerthVend'} style={{paddingLeft: 20}} />
                    </ListItem>
                    <ListItem button onClick={() => {onChangeRoute('/statements/ve/ocpi')}}>
                        <ListItemText primary={'OCPI'} style={{paddingLeft: 20}} />
                    </ListItem>
                </div>}
                
                {hasSectorAccess('reporting') && 
                <ListItem button onClick={() => {onChangeRoute('/reporting')}}>
                    <ListItemText primary={'Reporting'} />
                </ListItem>}
                
                {hasSectorAccess('commission') && 
                <ListItem button onClick={() => {onChangeRoute('/commissioning')}}>
                    <ListItemText primary={'Commission'} />
                </ListItem>}
                
                {hasSectorAccess('map') && 
                <ListItem button onClick={() => {onChangeRoute('/map')}}>
                    <ListItemText primary={'Map'} />
                </ListItem>}
                
                {hasSectorAccess('settings') && 
                <ListItem button onClick={() => {onChangeRoute('/settings')}}>
                    <ListItemText primary={'Settings'} />
                </ListItem>}
            </List>
            {/* <List>
                <ListItem button onClick={() => {onChangeRoute('/visits')}}>
                    <ListItemText primary={'Visits'} />
                </ListItem>
            </List> */}
            </div>
        </Drawer>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user.profile
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DrawerMenu))